import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import { Stack } from '@mui/material'
import { ReactComponent as OkIcon } from '../icons/OkIcon.svg'
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg'
import NanoMenuItem from './NanoMenuItem'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'

const InlineBadge = styled(Badge)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  '& .MuiBadge-badge': {
    right: 12,
    top: 10,
  },
}))

const propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

const defaultProps = {}

function NanoSelectMultiple({ options, value, handleSelect, placeholder, ...props }) {
  // @TODO: Avoid using NanoSelectMultiple for filters with large data sets.
  // Instead, use NanoAutocomplete (multiple) with the look and feel of NanoSelectMultiple (implementation pending).

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleValidate = () => {
    setOpen(false)
    handleSelect(selected)
  }
  const selectAll = () => setSelected(options.map((o) => o.value))
  const clearAll = () => setSelected([])

  return (
    <Select
      {...props}
      sx={{ bgcolor: 'white', height: 40 }}
      open={open}
      value={selected}
      multiple
      displayEmpty
      IconComponent={ArrowDownIcon}
      MenuProps={{
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: -24,
        },
      }}
      onOpen={() => setOpen(true)}
      onChange={(e) => setSelected(e.target.value)}
      onClose={() => setOpen(false)}
      renderValue={() =>
        selected.length ? (
          <InlineBadge color="secondary" badgeContent={selected.length}>
            <Typography variant="h5">{t(placeholder)}</Typography>
          </InlineBadge>
        ) : (
          <Typography variant="h5">{t(placeholder)}</Typography>
        )
      }
    >
      {options?.map((option) => [
        <NanoMenuItem
          key={option.value}
          checked={!!selected.includes(option.value)}
          listItemTextProps={{ primary: t(option.name) }}
          value={option.value}
          withCheckbox
        />,
      ])}
      <Stack
        direction="row"
        pt={2}
        spacing={3}
        justifyContent="space-between"
        sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}
      >
        <Button
          sx={{ minWidth: 208, mb: 3 }}
          color="grey"
          onClick={selected.length ? clearAll : selectAll}
          endIcon={selected.length ? <CloseIcon /> : <OkIcon stroke="black" />}
        >
          {selected.length ? t('clear') : t('select_all')}
        </Button>
        <Button onClick={handleValidate}>{t('validate')}</Button>
      </Stack>
    </Select>
  )
}

NanoSelectMultiple.propTypes = propTypes
NanoSelectMultiple.defaultProps = defaultProps

export default NanoSelectMultiple
