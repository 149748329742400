import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@mui/material'

const propTypes = { width: PropTypes.number, height: PropTypes.number }

const defaultProps = {
  width: 100,
  height: 32,
}

/**
 */
function SkeletonButton({ width, height, ...props }) {
  return (
    <Skeleton
      variant="rectangular"
      width={width}
      height={height}
      sx={{ 'border-radius': '10px', p: 1 }}
      {...props}
    />
  )
}

SkeletonButton.propTypes = propTypes
SkeletonButton.defaultProps = defaultProps

export default SkeletonButton
