import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import UserAvatarAndName from './UserAvatarAndName'

const propTypes = {
  loading: PropTypes.bool,
  source: PropTypes.oneOf(['manual', 'external', 'delivery', 'export', '']),
}

const defaultProps = { loading: false, source: '' }

/**
 * @param {Object} props
 */
function CalibrationCreatedByCell({ loading, source, ...props }) {
  const user = props
  return (
    <TableCell align="left">
      <UserAvatarAndName
        {...user}
        first_name={
          source === 'export' ? 'Export' : source === 'external' ? 'API' : user?.first_name
        }
        last_name={source === 'export' || source === 'external' ? '' : user?.last_name}
        loading={loading}
      />
    </TableCell>
  )
}

CalibrationCreatedByCell.propTypes = propTypes
CalibrationCreatedByCell.defaultProps = defaultProps

export default CalibrationCreatedByCell
