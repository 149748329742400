import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material'
import { useShallow } from 'zustand/react/shallow'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg'
import { CloseIcon } from '../icons/index'
import { sendEvent } from '../utils/analyticsUtils'
import NanoDialog from './NanoDialog'
import useDebounced from '../customHooks/useDebounced'
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized'
import useNewTabNavigate from '../customHooks/useNewTabNavigate'
import useListNavigation from '../customHooks/useListNavigation'
import SearchDeviceResult from './SearchDeviceResult'
import { useGlobalSearchStore } from '../globalSearchStore'
import useHotkey from '../customHooks/useHotkey'

const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)

const PAGE_SIZE = 15

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function SearchDeviceModal() {
  const { t } = useTranslation()
  const newTabNavigate = useNewTabNavigate()

  const {
    isOpen,
    toggleOpen,
    searchValue,
    setSearch,

    deviceResults,
    rowCount,
    fetchResults,
    clearResults,

    isLoading,
    setLoading,

    visitedDevices,
    fetchVisitedDevices,
  } = useGlobalSearchStore(useShallow((state) => state))

  const debouncedSearch = useDebounced(searchValue, 500)

  // si search est vide, on vient afficher l'historique des devices visités grâce au localStorage
  const showHistory = searchValue === '' && visitedDevices.length > 0

  const listRef = React.useRef(null)
  const scrollToItem = useCallback((index) => listRef?.current?.scrollToRow(index), [listRef])

  const { activeIndex, onHoverItem, focusItem } = useListNavigation({
    items: showHistory ? visitedDevices : deviceResults,
    onSelect: (device) => {
      if (device?.device_reference) {
        toggleOpen(false)
        newTabNavigate(`/devices/${device?.device_reference}`)
      }
    },
    scrollToItem,
    enabled: isOpen,
  })

  useHotkey({
    key: 'mod+k',
    callback: toggleOpen,
    preventDefault: true,
  })

  useEffect(() => {
    if (isOpen && searchValue === '') {
      focusItem(0)
      fetchVisitedDevices()
    }
  }, [isOpen, searchValue, fetchVisitedDevices, focusItem])

  // Déclenche un fetch à chaque changement de search
  useEffect(() => {
    clearResults()
    focusItem(0)
    if (debouncedSearch !== '') {
      sendEvent('search_device', { query: debouncedSearch })
      setLoading(true)
      ;(async () => {
        try {
          await fetchResults({ startIndex: 0, stopIndex: PAGE_SIZE - 1 })
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [debouncedSearch, clearResults, fetchResults, setLoading, focusItem])

  const height = window.innerHeight * 0.5 - 50

  return (
    <NanoDialog
      open={isOpen}
      onClose={() => toggleOpen(false)}
      disablePortal
      scroll="paper"
      PaperProps={{
        sx: {
          width: '50%',
          maxHeight: '75%',
        },
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start', // Positionne la boîte de dialogue en haut
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '1px solid rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <TextField
        autoFocus
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
            {
              border: '1px solid rgba(0, 0, 0, 0.08)',
            },
        }}
        fullWidth
        placeholder={t('search_bin_ph')}
        onChange={(e) => setSearch(e.target.value)}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {isLoading && <CircularProgress color="inherit" size={24} />}
              {searchValue && (
                <IconButton onClick={() => setSearch('')}>
                  <CloseIcon width={24} height={24} />
                </IconButton>
              )}
              <InputAdornment position="end">
                <Typography variant="body2" color="textSecondary">
                  <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Typography variant="body2" color="textSecondary" level="kbd">
                      {isMac ? '⌘' : 'Ctrl+'}K
                    </Typography>
                  </Stack>
                </Typography>
              </InputAdornment>
            </>
          ),
          style: { backgroundColor: 'white' },
        }}
      />

      {showHistory && (
        <Typography sx={{ pt: 2 }} variant="body1" color="textSecondary">
          {t('device_history')}
        </Typography>
      )}
      <Box
        sx={{
          pt: 2,
          height: '100%',
          maxHeight: height,
          minHeight: height,
          display: 'flex',
          '& .MuiMenuItem-root:hover': {
            backgroundImage: 'initial',
            backgroundColor: 'initial',
          },
        }}
      >
        <InfiniteLoader
          isRowLoaded={({ index }) => showHistory || Boolean(deviceResults[index])}
          loadMoreRows={fetchResults}
          rowCount={showHistory ? visitedDevices.length : rowCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  ref={(ref) => {
                    listRef.current = ref
                    registerChild(ref)
                  }}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowCount={
                    showHistory
                      ? visitedDevices.length
                      : displayedRowCounts({
                          results: deviceResults,
                          remoteCount: rowCount,
                          isInitialLoading: isLoading,
                        })
                  }
                  rowHeight={113}
                  rowRenderer={({ key, index, style }) => (
                    <div
                      key={key}
                      style={{
                        ...style,
                        backgroundColor:
                          activeIndex === index ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        borderRadius: '8px',
                      }}
                      onMouseEnter={() => onHoverItem(index)}
                      onMouseMove={() => {
                        onHoverItem(index)
                      }}
                    >
                      <SearchDeviceResult
                        loading={showHistory ? false : deviceResults[index]?.loading}
                        device={showHistory ? visitedDevices[index] : deviceResults[index]}
                        highlightText={showHistory ? '' : debouncedSearch}
                        onClick={(event) => {
                          const device = showHistory ? visitedDevices[index] : deviceResults[index]
                          const openedNewTab = newTabNavigate(
                            `/devices/${device?.device_reference}`,
                            { event }
                          )
                          if (!openedNewTab) {
                            toggleOpen(false)
                          }
                        }}
                      />
                    </div>
                  )}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </Box>
    </NanoDialog>
  )
}
SearchDeviceModal.propTypes = propTypes
SearchDeviceModal.defaultProps = defaultProps

/**
 * How many rows to display while rows are being loaded, including
 * only a few loading rows.
 */
function displayedRowCounts({ remoteCount, results, isInitialLoading }) {
  const maxLoadingRows = 4
  const loadedCount = results.filter((result) => !result.loading).length

  if (isInitialLoading) {
    return maxLoadingRows
  } else if (loadedCount < remoteCount) {
    return loadedCount + Math.min(maxLoadingRows, remoteCount - loadedCount)
  } else {
    return loadedCount
  }
}

export default SearchDeviceModal
