import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreAction from '../../../shared/components/MoreAction'
import { Skeleton, Stack, TableCell } from '@mui/material'
import ValidateCommandButton from '../components/ValidateCommandButton'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

const propTypes = {
  handleEdit: PropTypes.func.isRequired,
  /** indicate if we should show the edit button */
  disableOrderEdition: PropTypes.bool,
  loading: PropTypes.bool,
  /** indicate if the order is in the future from now */
  isValidated: PropTypes.bool,
}

const defaultProps = {
  disableOrderEdition: true,
  isValidated: false,
  loading: false,
}

function MoreActionOrderCell({ handleEdit, disableOrderEdition, loading, isValidated, ...props }) {
  const [validated, setValidated] = useState(isValidated)

  const handleUpdateValidated = (newValidated) => {
    setValidated(newValidated)
  }

  return (
    <>
      {loading ? (
        <Skeleton width="80%" />
      ) : (
        <TableCell align="right">
          <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            {!disableOrderEdition && (
              <ValidateCommandButton
                isValidated={isValidated}
                order={props}
                onUpdateValidated={handleUpdateValidated}
              />
            )}
            {!disableOrderEdition && !isValidated && !validated && (
              <span
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <MoreAction onClickEdit={handleEdit} />
              </span>
            )}
          </Stack>
        </TableCell>
      )}
    </>
  )
}

MoreActionOrderCell.propTypes = propTypes
MoreActionOrderCell.defaultProps = defaultProps

export default MoreActionOrderCell
