import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createFilterOptions, IconButton, Stack, Typography, useTheme } from '@mui/material'
import ButtonAdd from '../ButtonAdd'
import { useTranslation } from 'react-i18next'
import NanoMenuItem from '../NanoMenuItem'
import NanoAvatar from '../NanoAvatar'
import NanoAutocomplete from '../NanoAutocomplete'
import { PoiIcon, ArrowBackIcon, ArrowRightIconBig } from '../../icons/index'

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      section: PropTypes.string,
    })
  ),
  readOnly: PropTypes.bool,
  fetchOptions: PropTypes.func.isRequired,
  withGroups: PropTypes.bool,
}

const defaultProps = {
  label: '',
  error: null,
  value: [],
  required: false,
  readOnly: false,
  noOptionsText: null,
  withGroups: true,
}

// Filter group options as the API would do, but locally
const filterOptions = createFilterOptions({
  stringify: (group) => `${group.group_name} ${group.client_poi_id} ${group.sold_to}`,
})

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDevicesOrGroups({
  label,
  onChange,
  error,
  value,
  required,
  readOnly,
  fetchOptions,
  withGroups,
}) {
  const [inputValue, setInputValue] = useState('')
  const [selectedGroup, setSelectedGroup] = useState(null)
  const theme = useTheme()
  const { t } = useTranslation()
  /** @type {Partial<import('@mui/material').AutocompleteProps>} */
  const overrideProps = {}
  let noOptText = (
    <Typography sx={{ pt: 3 }} align="center">
      {t('no_groups_to_add')}
    </Typography>
  )

  const onChangeValue = (_, data) => {
    // we retrieve the last item selected and check its farm, if all devices for this farm are selected, then replace device list in input value by farm name
    const farmId = data[data.length - 1]?.farm_id
    if (data.filter((d) => d.farm_id === farmId).length === selectedGroup?.devices.length) {
      onChange(_, data.filter((d) => d.farm_id !== farmId).concat(selectedGroup))
      setSelectedGroup(null)
    } else {
      onChange(_, data)
    }
  }
  if (selectedGroup) {
    // @ts-ignore
    overrideProps.options = selectedGroup?.devices.map((d) => ({
      ...d,
      section: 'bin',
      label: d.device_name,
      id: d.device_reference,
    }))

    const renderDevices = (children) => (
      <Stack sx={{ m: 2 }}>
        <IconButton
          sx={{ p: 0, alignSelf: 'flex-start' }}
          onClick={() => {
            setSelectedGroup(null)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 1 }}>
          <Typography variant="body1" color="text.secondary">
            {selectedGroup.group_name}
          </Typography>

          {withGroups && (
            <ButtonAdd
              text={t('add_all_group')}
              onClick={() => {
                onChange(
                  null,
                  value.filter((v) => v.farm_id !== selectedGroup.group_id).concat(selectedGroup)
                )
                setSelectedGroup(null)
              }}
            />
          )}
        </Stack>
        {children || (
          <Typography sx={{ pt: 3 }} align="center">
            {t('no_devices_for_group')}
          </Typography>
        )}
      </Stack>
    )
    overrideProps.renderGroup = (params) => renderDevices(params.children)
    noOptText = renderDevices(null)
  } else {
    overrideProps.renderOption = (props, option) => (
      <NanoMenuItem
        icon={
          <NanoAvatar sx={{ width: 40, height: 40, backgroundColor: 'greyBackground.main' }}>
            <PoiIcon fill={theme.palette.text.secondary} />
          </NanoAvatar>
        }
        listItemTextProps={{
          primary: option.label,
          secondary: `${t('binWithCount2', { count: option.devices.filter((device) => !device.is_combined).length })}${option.devices?.filter((device) => device.is_combined).length ? ` (${t('combinedWithCount', { count: option.devices?.filter((device) => device.is_combined).length })})` : ''}`,
        }}
        listItemProps={{ sx: { ml: 0, mr: 2 } }}
        {...props}
        onClick={(event) => {
          event.stopPropagation()
          setInputValue('')
          setSelectedGroup(option)
        }}
      >
        <Stack direction="row" alignItems="center" alignContent="flex-start">
          <IconButton>
            <ArrowRightIconBig />
          </IconButton>
        </Stack>
      </NanoMenuItem>
    )
  }
  return (
    <NanoAutocomplete
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      label={label}
      disabled={readOnly}
      filterOptions={filterOptions}
      fetchOptions={selectedGroup ? undefined : fetchOptions} // retrieve all the options (initial state, pagination)
      onChange={onChangeValue}
      noOptionsText={noOptText}
      error={error}
      value={value}
      required={required}
      {...overrideProps}
      sx={{ maxHeight: 300, overflow: 'auto' }}
    />
  )
}

SelectDevicesOrGroups.propTypes = propTypes
SelectDevicesOrGroups.defaultProps = defaultProps

export default SelectDevicesOrGroups
