import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Badge, Box, IconButton, Link, Popover, useMediaQuery, useTheme } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ReactComponent as NotificationIcon } from '../../shared/icons/NotificationIcon.svg'
import UserMenu from './components/UserMenu'
import { useSessionStore } from '../../shared/store'
import NotificationsList from '../notifications/components/NotificationsList'
import { SearchIcon } from '../../shared/icons/index'
import ButtonWhite from '../../shared/components/ButtonWhite'
import NanoChip from '../../shared/components/NanoChip'
import useHotkey from '../../shared/customHooks/useHotkey'
import { useGlobalSearchStore } from '../../shared/globalSearchStore'

const propTypes = {
  title: PropTypes.string,
  renderTitle: PropTypes.element,
  actions: PropTypes.element,
  justifyContent: PropTypes.string,
  back: PropTypes.bool,
  backToParent: PropTypes.bool,
  canNavigate: PropTypes.bool,
  handlePrevious: PropTypes.oneOf([PropTypes.func, PropTypes.bool]),
  handleNext: PropTypes.func,
}

const defaultProps = {
  back: false,
  backToParent: false,
  canNavigate: false,
  renderTitle: null,
  actions: null,
  justifyContent: 'space-between',
  title: null,
  handlePrevious: false,
  handleNext: false,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */

function TopPage({
  title,
  renderTitle,
  actions,
  justifyContent,
  back,
  backToParent,
  canNavigate,
  handlePrevious,
  handleNext,
}) {
  const { t } = useTranslation()
  const ref = useRef(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const searchModalOpen = useGlobalSearchStore((state) => state.isOpen)
  const toggleSearchModal = useGlobalSearchStore((state) => state.toggleOpen)
  const { fetchUnread, unreadNotificationsCount } = useSessionStore((state) => state)
  const handleDrawerOpenClose = () => (open ? setOpen(false) : setOpen(true))
  const [notificationsAnchor, setNotificationsAnchor] = useState(null)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  useEffect(() => {
    fetchUnread()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentPath = window.location.pathname
  const pathSegments = currentPath.split('/').filter((segment) => segment)
  const parentPath = '/' + pathSegments.slice(0, -1).join('/')

  useHotkey({
    key: 'up',
    callback: handlePrevious,
    preventDefault: true,
    enabled: handlePrevious && !searchModalOpen,
  })
  useHotkey({
    key: 'down',
    callback: handleNext,
    preventDefault: true,
    enabled: handleNext && !searchModalOpen,
  })

  return (
    <Stack>
      <Stack
        pt={2}
        pb={2}
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent={back ? 'space-between' : 'flex-end'}
        position="relative"
      >
        {back && (
          <Stack direction="row" justifyContent="flex-start">
            <IconButton
              sx={{ paddingLeft: 0 }}
              onClick={() => {
                if (backToParent) navigate(parentPath)
                else if (window.history.length > 1) navigate(-1)
                else {
                  navigate('/devices')
                }
              }}
            >
              <NanoChip
                label={t('back')}
                section="back"
                sx={{ backgroundColor: '#FAFAFB', border: '1px solid #EFEFF3' }}
              />
            </IconButton>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="50%"
          sx={{ transform: 'translateX(-50%)' }}
        >
          {canNavigate && (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <IconButton
                onClick={handlePrevious}
                sx={{ visibility: handlePrevious ? 'visible' : 'hidden' }}
              >
                <NanoChip
                  label={!mobileFormat && t('previous')}
                  section="up"
                  sx={{ backgroundColor: '#FAFAFB', border: '1px solid #EFEFF3' }}
                />
              </IconButton>

              <IconButton
                onClick={handleNext}
                sx={{ visibility: handleNext ? 'visible' : 'hidden' }}
              >
                <NanoChip
                  label={!mobileFormat && t('next')}
                  section="down"
                  sx={{ backgroundColor: '#FAFAFB', border: '1px solid #EFEFF3' }}
                />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          alignContent="flex-start"
          justifyContent="flex-end"
        >
          <ButtonWhite
            variant="outlined"
            text={t('search')}
            startIcon={<SearchIcon />}
            onClick={() => toggleSearchModal(true)}
            sx={{
              borderColor: 'grey.main',
              color: 'black !important',
              padding: 1,
              marginRight: '8px !important',
              '&:hover, &.Mui-focusVisible': {
                borderColor: '#000000',
                backgroundColor: 'transparent',
              },
            }}
          />
          <IconButton
            onClick={(event) => {
              setNotificationsAnchor(event.currentTarget)
            }}
          >
            <Badge
              badgeContent={unreadNotificationsCount.all}
              // @ts-ignore
              color="priorityHighRevert"
            >
              <NotificationIcon color="black" />
            </Badge>
          </IconButton>
          <Popover
            sx={{ height: 500 }}
            open={!!notificationsAnchor}
            anchorEl={notificationsAnchor}
            onClose={() => setNotificationsAnchor(null)}
            slotProps={{ paper: { ref } }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ px: 3, width: '40vw', maxWidth: 800 }}>
              <NotificationsList popoverElement={ref} />
            </Box>
            <Box
              sx={{
                p: 2,
                position: 'sticky',
                bottom: 0,
                zIndex: 99,
                backgroundColor: 'white',
              }}
            >
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={{ pathname: '/notifications' }}
              >
                <Typography textAlign="center" variant="h5" color="secondary">
                  {t('seeAllNotifications')}
                </Typography>
              </Link>
            </Box>
          </Popover>
          <IconButton onClick={handleDrawerOpenClose}>
            <UserMenu drawerOpen={open} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        sx={{ pb: 2 }}
        direction="row"
        alignItems="center"
        alignContent="flex-start"
        justifyContent={justifyContent}
      >
        {renderTitle || (
          <Typography variant="h1" color="black">
            {t(title)}
          </Typography>
        )}
        {actions}
      </Stack>
    </Stack>
  )
}

TopPage.propTypes = propTypes
TopPage.defaultProps = defaultProps

export default TopPage
