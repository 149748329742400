import React from 'react'
import PropTypes from 'prop-types'
import { client } from '../../apiClient'
import SelectDevicesOrGroups from './SelectDevicesOrGroups'

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      section: PropTypes.string,
    })
  ),
  readOnly: PropTypes.bool,
  withCombined: PropTypes.bool,
}

const defaultProps = {
  label: '',
  error: null,
  value: [],
  required: false,
  readOnly: false,
  withCombined: false,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDevicesAndGroups({
  label,
  onChange,
  error,
  value,
  required,
  readOnly,
  withCombined,
}) {
  const fetchOptions = async () => {
    const response = await client.GET('/v1/groups', {
      params: {
        query: {
          is_poi: true,
        },
      },
    })

    return response.data.map((group) => {
      const devices = group.devices.filter((device) => withCombined || !device.is_combined)
      return {
        label: group.group_name,
        id: group.group_id,
        section: 'group',
        ...group,
        devices,
      }
    })
  }
  return (
    <SelectDevicesOrGroups
      label={label}
      readOnly={readOnly}
      onChange={onChange}
      error={error}
      value={value}
      required={required}
      fetchOptions={fetchOptions}
    />
  )
}

SelectDevicesAndGroups.propTypes = propTypes
SelectDevicesAndGroups.defaultProps = defaultProps

export default SelectDevicesAndGroups
