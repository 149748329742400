import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getRequest, client } from '../apiClient'
import NanoTable from './NanoTable'
import SimpleCell from './SimpleCell'
import CalibrationDateCell from './CalibrationDateCell'
import { ToastContext } from '../../shared/contexts'
import DateCell from './DateCell'
import MoreActionCalib from '../../modules/admin/calibrating/MoreActionCalib'
import CalibLevelModal from '../../modules/device/components/CalibLevelModal'
import CalibDeliveryModal, {
  useCalibDeliveryModal,
} from '../../modules/device/components/CalibDeliveryModal'
import DeviceExpandedCell from './DeviceExpandedCell'
import CalibrationCreatedByCell from './CalibrationCreatedByCell'

const propTypes = {
  filters: PropTypes.string,
  withDevice: PropTypes.bool,
}

const defaultProps = { withDevice: true, filters: null }

const columns = (withDevice, t, handleEdit) => [
  !withDevice
    ? {
        header: t('bin_one'),
        width: 225,
        format: (row) => <DeviceExpandedCell {...row} sx={{ pr: 3 }} />,
      }
    : {
        format: () => null,
        width: 0,
      },
  !withDevice
    ? {
        header: t('group_other'),
        width: 250,
        format: (row) => <SimpleCell loading={row.loading} data={row.poi_name} sx={{ pr: 3 }} />,
      }
    : {
        format: () => null,
        width: 0,
      },
  {
    header: t('date'),
    width: 260,
    format: (row) => (
      <CalibrationDateCell
        loading={row.loading}
        date={row.calibration_date}
        type={row.type}
        sx={{ pr: 3 }}
      />
    ),
  },
  {
    header: t('quantity'),
    width: 150,
    format: (row, t) => (
      <SimpleCell
        loading={row.loading}
        data={t('number_workspace_filling_unit', { value: row.quantity })}
        sx={{ pr: 3 }}
      />
    ),
  },
  {
    header: t('type'),
    width: 120,
    format: (row) => <SimpleCell loading={row.loading} data={t(row.type)} sx={{ pr: 3 }} />,
  },
  {
    header: t('created_at'),
    width: 200,
    format: (row) => <DateCell loading={row.loading} date={row.created_at} sx={{ pr: 3 }} />,
  },
  {
    header: t('done_by'),
    width: 300,
    format: (row) => (
      <CalibrationCreatedByCell
        loading={row.loading}
        source={row.source}
        {...row.created_by}
        sx={{ pr: 3 }}
      />
    ),
  },
  {
    header: 'actions',
    width: 50,
    format: (row) => (
      <MoreActionCalib
        handleUpdateOrDelete={(action) => handleEdit(action, row)}
        type={row.type}
        sx={{ pr: 3 }}
      />
    ),
  },
]

function CalibrationsTable({ filters, withDevice }) {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const toastContext = useContext(ToastContext)
  const [remoteRowCount, setRemoteRowCount] = useState()
  const pageSize = 15
  const [defaultValues, setDefaultValues] = useState({})
  const [formLevelIsOpen, setFormLevelIsOpen] = useState(false)
  const { calibDeliveryFormIsOpen, openCalibDeliveryModal } = useCalibDeliveryModal({
    useUrl: false,
  })
  const [dataLoading, setDataLoading] = useState(true)

  const { t } = useTranslation()

  const getData = useCallback(async () => {
    setDataLoading(true)
    setPage(1)
    return getRequest(
      `v2/calibrations?page=1&page_size=${pageSize}&excludeNanolike=true&${filters}`
    )
      .then((response) => {
        setRemoteRowCount(response.data.rowCount)
        setData(response.data.results)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [filters])

  useEffect(() => {
    getData()
  }, [getData])

  const loadMoreRows = useCallback(() => {
    getRequest(
      `v2/calibrations?page=${page + 1}&page_size=${pageSize}&excludeNanolike=true&${filters}`
    ).then((response) => {
      const { results, ...pagination } = response.data
      setData([...data, ...results])
      setPage(pagination.page)
    })
  }, [data, filters, page])

  /**
   * operation called by the MoreActionAlerts
   * @param {'update' | 'delete'} action
   * @param {import('../../../openapiDoc').components['schemas']['calibrations']} row
   */

  const handleUpdateOrDelete = (action, row) => {
    if (row.type === 'level') {
      if (action === 'delete') {
        return client
          .DELETE('/internal/calibration-level/{id}', {
            params: { path: { id: row.idCalibration } },
          })
          .then(() => getData())
      } else {
        setDefaultValues(row)
        setFormLevelIsOpen(true)
      }
    } else {
      if (action === 'delete') {
        return client
          .DELETE('/internal/calibration-delivery/{id}', {
            params: { path: { id: row.idCalibration } },
          })
          .then(() => getData())
      } else {
        setDefaultValues(row)
        openCalibDeliveryModal(true)
      }
    }
  }

  const handleCalibDidCreate = () => {
    toastContext.sendMessage(t('calib_snackbar_alert_updated'))
    getData()
  }
  return (
    <div>
      <NanoTable
        columns={columns(withDevice, t, handleUpdateOrDelete)}
        data={data}
        loading={dataLoading}
        remoteRowCount={remoteRowCount}
        fetchPage={loadMoreRows}
      />

      {formLevelIsOpen && (
        <CalibLevelModal
          isOpen={formLevelIsOpen}
          onClose={() => {
            setFormLevelIsOpen(false)
            setDefaultValues({})
          }}
          onSuccess={handleCalibDidCreate}
          row={defaultValues}
        />
      )}
      {calibDeliveryFormIsOpen && (
        <CalibDeliveryModal
          isOpen={calibDeliveryFormIsOpen}
          onClose={() => {
            openCalibDeliveryModal(false)
            setDefaultValues({})
          }}
          onSuccess={handleCalibDidCreate}
          row={defaultValues}
        />
      )}
    </div>
  )
}

CalibrationsTable.propTypes = propTypes
CalibrationsTable.defaultProps = defaultProps

export default CalibrationsTable
