import React from 'react'
import PropTypes from 'prop-types'
import LevelForecastCell from '../../dashboard/cells/LevelForecastCell'
import RemainingDaysCell from '../../dashboard/cells/RemainingDaysCell'
import NanoTable from '../../../shared/components/NanoTable'
import {
  useCanNavigateStore,
  useSessionStore,
  useWorkspaceSettingsStore,
} from '../../../shared/store'
import LevelTodayCell from '../../dashboard/cells/LevelTodayCell'
import LevelTodayErrorCell from '../../dashboard/cells/LevelTodayErrorCell'
import { TableCell } from '@mui/material'
import DeviceTitle from '../../../shared/components/DeviceTitle'
import DeliveryCell from '../../dashboard/cells/DeliveryCell'
import ActionCell from '../../dashboard/cells/ActionCell'
import useNewTabNavigate from '../../../shared/customHooks/useNewTabNavigate'

const columnsDashboard = (user, adminMode, disableOrders) => [
  {
    width: 302,
    format: (row) => (
      <TableCell sx={{ pr: 2 }}>
        <DeviceTitle {...row} />
      </TableCell>
    ),
  },

  {
    format: (row) =>
      row.status === 'ok' ? (
        <LevelTodayCell {...row} level={row.levels[0]} />
      ) : (
        <LevelTodayErrorCell {...row} />
      ),
    width: 420,
  },
  ...[...Array(4).keys()].map((i) => ({
    format: (row) =>
      row.loading ? (
        <LevelForecastCell loading={row.loading} />
      ) : row.status === 'ok' && !row.has_parent_combined_device ? (
        <LevelForecastCell {...row} day={i + 1} level={row.levels[i + 1]} />
      ) : null,
    width: 150,
    hide: !!adminMode || !user.configuration.display_forecast || 'lg',
  })),
  {
    format: (row) =>
      row.loading ? (
        <RemainingDaysCell loading={row.loading} />
      ) : row.status === 'ok' && !row.has_parent_combined_device ? (
        <RemainingDaysCell {...row} />
      ) : null,
    width: 400,
    hide: !!adminMode || !user.configuration.display_remaining || 'sm',
  },
  {
    format: (row) =>
      row.loading ? (
        <DeliveryCell loading={row.loading} />
      ) : row.device_last_delivery && row.status === 'ok' && !row.has_parent_combined_device ? (
        <DeliveryCell {...row} />
      ) : null,
    width: 400,
    hide: !!adminMode || !user.configuration.display_last_order || 'md',
  },
  {
    header: 'actions',
    format: (row) => (
      <ActionCell
        {...row}
        displayOrder={user.configuration.display_quick_action_order && disableOrders}
        adminMode={adminMode}
      />
    ),
    width: 400,
  },
]

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  fromMap: PropTypes.bool,
  fetchPage: PropTypes.func,
  remoteRowCount: PropTypes.number,
  adminMode: PropTypes.func,
  nanoPaperProps: PropTypes.shape({}),
  canNavigate: PropTypes.bool,
}

const defaultProps = {
  loading: true,
  fromMap: false,
  fetchPage: null,
  remoteRowCount: null,
  adminMode: null,
  nanoPaperProps: null,
  canNavigate: false,
}

function DeviceList({
  data,
  loading,
  remoteRowCount,
  fetchPage,
  adminMode,
  nanoPaperProps,
  canNavigate,
}) {
  const user = useSessionStore((state) => state.user)
  const newTabNavigate = useNewTabNavigate()
  const setCanNavigate = useCanNavigateStore((state) => state.setCanNavigate)
  const disableOrders =
    useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'
  const handleClick = ({ rowData, event }) => {
    if (!loading && !rowData?.loading) {
      setCanNavigate(canNavigate)
      newTabNavigate(`/devices/${rowData.device_reference}`, { event })
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <NanoTable
        columns={columnsDashboard(user, adminMode, !disableOrders)}
        data={data}
        loading={loading}
        ROW_HEIGHT={114}
        tableProps={{
          onRowClick: handleClick,
          // @ts-ignore
          disableHeader: true,
        }}
        remoteRowCount={remoteRowCount}
        fetchPage={fetchPage}
        nanoPaperProps={{ sx: { pt: 0 }, ...nanoPaperProps }}
      />
    </div>
  )
}

DeviceList.propTypes = propTypes
DeviceList.defaultProps = defaultProps

export default DeviceList
