import { debounce } from '@mui/material'
import { useState, useEffect, useMemo } from 'react'

/**
 * useState but with a current and debounced value
 */
function useDebounced(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const setDebouncedValueDebounced = useMemo(() => debounce(setDebouncedValue, delay), [delay])

  useEffect(() => {
    setDebouncedValueDebounced(value)
  }, [setDebouncedValueDebounced, value])

  return debouncedValue
}

export default useDebounced
