import { useCallback, useEffect, useState } from 'react'
import useHotkey from './useHotkey'

/**
 * Helper to manage highlighting state of a list of items
 */
function useListNavigation({ items, onSelect, scrollToItem, enabled = true }) {
  const [activeIndex, setActiveIndex] = useState(0)
  // Keep track whether the user is using the keyboard to navigate
  // to avoid the mouse to accidentally change the active item
  // at the same time
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false)
  const activeItem = items[activeIndex]

  const focusItem = useCallback(
    (index) => {
      setActiveIndex(index)
      scrollToItem?.(index)
    },
    [scrollToItem]
  )

  const moveHighlight = (offset) => {
    const newIndex = constrain(0, activeIndex + offset, items.length - 1)
    if (newIndex !== activeIndex) {
      focusItem(newIndex)
    }
  }

  useEffect(() => {
    const onMouseMove = () => {
      setIsUsingKeyboard(false)
    }
    const onKeyDown = () => {
      setIsUsingKeyboard(true)
    }
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('mousemove', onMouseMove)
    }
  })

  const onHoverItem = useCallback(
    (index) => {
      if (!isUsingKeyboard && index !== activeIndex) {
        setActiveIndex(index)
      }
    },
    [activeIndex, isUsingKeyboard]
  )

  useHotkey({
    key: 'down',
    callback: () => moveHighlight(+1),
    preventDefault: true,
    enabled,
  })
  useHotkey({
    key: 'tab',
    callback: () => moveHighlight(+1),
    preventDefault: true,
    enabled,
  })
  useHotkey({
    key: 'up',
    callback: () => moveHighlight(-1),
    preventDefault: true,
    enabled,
  })
  useHotkey({
    key: 'shift+tab',
    callback: () => moveHighlight(-1),
    preventDefault: true,
    enabled,
  })
  useHotkey({
    key: 'enter',
    callback: () => {
      onSelect(items[activeIndex])
    },
    preventDefault: true,
    enabled,
  })

  return {
    activeItem,
    activeIndex,
    onHoverItem,
    focusItem,
  }
}

function constrain(min, x, max) {
  return Math.min(Math.max(min, x), max)
}

export default useListNavigation
