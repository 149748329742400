import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SiloImage } from '../icons/SiloImage.svg'

const propTypes = {
  searchField: PropTypes.string,
  customText: PropTypes.string,
}

const defaultProps = {
  searchField: null,
  customText: null,
}

function NotFoundData({ customText }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ margin: 'auto', display: 'center', py: 6, px: 8 }}>
      <Grid direction="column" display="flex" alignItems="center" justifyContent="center" container>
        <SiloImage />
        <Grid paddingTop="28px" width="301px" textAlign="center">
          <Typography variant="body1">
            {customText ? t(customText) : t('not_found_data')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

NotFoundData.propTypes = propTypes
NotFoundData.defaultProps = defaultProps

export default NotFoundData
