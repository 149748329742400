import { useEffect } from 'react'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { client } from './apiClient.js'

const HISTORY_SIZE = 5
const PAGE_SIZE = 15

const useGlobalSearchStore = create(
  // persist to localStorage
  persist(
    (set, get) => ({
      isOpen: false,
      searchValue: '',
      deviceResults: [
        // { device_reference: '1', deviceContent: 'Content 1', group_poi: { group_poi_name: 'Group 1' } },
        // or
        // { loading: true }
      ],
      rowCount: 0,
      isLoading: false,

      visitedDeviceIds: [],
      visitedDevices: [],

      toggleOpen(isOpen = !get().isOpen) {
        set({ isOpen })
      },

      setSearch(searchValue) {
        set({ searchValue })
      },

      clearResults() {
        set({ deviceResults: [], rowCount: 0, isLoading: false })
      },

      setLoading(isLoading) {
        set({ isLoading })
      },

      async fetchResults({ startIndex, stopIndex }) {
        const { searchValue } = get()
        if (searchValue === '') {
          set({ isLoading: false, rowCount: 0, deviceResults: [] })
          return
        }

        // Indicates that the rows are being loaded
        set((state) => ({
          deviceResults: [
            ...state.deviceResults.slice(0, startIndex),
            ...Array.from({ length: stopIndex - startIndex }, () => ({ loading: true })),
            ...state.deviceResults.slice(stopIndex),
          ],
        }))

        const pageSlices = pageSlicesToFetch({ startIndex, stopIndex, pageSize: PAGE_SIZE })
        const responses = await Promise.all(
          pageSlices.map(({ page }) =>
            client.GET('/v2/dashboard/', {
              params: {
                query: {
                  search: searchValue,
                  page,
                  page_size: PAGE_SIZE,
                },
              },
            })
          )
        )

        // Search has changed, ignore the results
        if (get().searchValue !== searchValue) return

        const newResults = responses.flatMap((response) => response.data.results)

        set((state) => ({
          isLoading: false,
          rowCount: responses[0]?.data?.rowCount ?? 0,
          deviceResults: [
            ...state.deviceResults.slice(0, startIndex),
            ...newResults,
            ...state.deviceResults.slice(startIndex + newResults.length),
          ],
        }))
      },

      /**
       * Track that a device was visited
       */
      addVisitedDevice(deviceId) {
        const newVisitedDeviceIds = Array.from(
          new Set([
            // Push the new device to the front of the queue
            deviceId,
            ...get().visitedDeviceIds,
          ])
        ).slice(0, HISTORY_SIZE)

        const hasChanged = newVisitedDeviceIds.some(
          (id, index) => id !== get().visitedDeviceIds[index]
        )
        if (hasChanged) {
          set({ visitedDeviceIds: newVisitedDeviceIds })
        }
      },

      async fetchVisitedDevices() {
        const { visitedDeviceIds, visitedDevices } = get()

        if (visitedDeviceIds.length === 0) {
          set({ visitedDevices: [] })
        }

        const isAlreadyFetched =
          visitedDeviceIds.length === visitedDevices.length &&
          visitedDeviceIds.every((id, index) => id === visitedDevices[index]?.device_reference)
        if (isAlreadyFetched) {
          return
        }

        const response = await client.GET('/v2/dashboard/', {
          params: {
            query: {
              page: 1,
              page_size: visitedDeviceIds.length,
              device_reference: visitedDeviceIds.join(','),
            },
          },
        })

        set({
          visitedDevices:
            // Sort visitedDevices to match the order of visitedDevices
            response.data.results.sort(
              (a, b) =>
                visitedDeviceIds.indexOf(a.device_reference) -
                visitedDeviceIds.indexOf(b.device_reference)
            ),
        })
      },
    }),
    {
      name: 'globalSearch',
      partialize: (state) => ({
        visitedDeviceIds: state.visitedDeviceIds,
        // searchValue: state.searchValue, // We prefer to show the history when opening the app
      }),
    }
  )
)

/**
 * Component to track visited devices
 */
const TrackVisitedDevice = ({ id }) => {
  const addVisitedDevice = useGlobalSearchStore((state) => state.addVisitedDevice)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => addVisitedDevice(id), [])
  return null
}

/**
 * Return the list of pages to fetch to get items from a startIndex to a stopIndex
 * @returns {Array<{ page: number, start: number, end: number }>}
 *
 * Example:
 * pageSlicesToFetch({ startIndex: 14, stopIndex: 18, pageSize: 10 }) => [
 *  { page: 2, start: 4, end: 8 },
 * ]
 */
function pageSlicesToFetch({ startIndex, stopIndex, pageSize }) {
  const startPage = Math.floor(startIndex / pageSize) + 1
  const endPage = Math.floor(stopIndex / pageSize) + 1

  const pageSlices = []
  for (let page = startPage; page <= endPage; page++) {
    pageSlices.push({
      page,
      start: page === startPage ? startIndex % pageSize : 0,
      end: page === endPage ? (stopIndex % pageSize) + 1 : pageSize,
    })
  }

  return pageSlices
}

export { TrackVisitedDevice, useGlobalSearchStore }
