import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  BatteryProblemIcon,
  IceTrappedIcon,
  PlugSocketIcon,
  WifiOffIcon,
  WrenchIcon,
  InfoIcon,
} from '../icons/index'
import { isKnownOperationSuggested, OPERATION_SUGGESTED } from '../models/operationSuggested'
import QuickInfo from './QuickInfo'

const OPERATION_SUGGESTED_ICON = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: PlugSocketIcon,
  [OPERATION_SUGGESTED.MOVE]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_BATTERY]: BatteryProblemIcon,
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_ELEC]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_SENSOR]: WrenchIcon,
  [OPERATION_SUGGESTED.OUT_OF_DATA]: WifiOffIcon,
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: WifiOffIcon,
  [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: WrenchIcon,
  [OPERATION_SUGGESTED.SHIM]: WrenchIcon,
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: IceTrappedIcon,
})

const OPERATION_SUGGESTED_SHORT = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_short',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_short',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_short',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_short',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_short',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_short',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_short',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: 'device_status_problem_out_of_data_station_short',
  [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: 'device_status_problem_reinstall_same_leg_short',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_short',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_short',
})

const OPERATION_SUGGESTED_TITLE = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_title',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_title',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_title',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_title',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_title',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_title',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_title',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: 'device_status_problem_out_of_data_station_title',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_title',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_title',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_title',
})

const OPERATION_SUGGESTED_EXPLANATION = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_explanation',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_explanation',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_explanation',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_explanation',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_explanation',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_explanation',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_explanation',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]:
    'device_status_problem_out_of_data_station_explanation',
  [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: 'device_status_problem_reinstall_same_leg_explanation',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_explanation',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_explanation',
})

const getOperationSuggestedTitle = (t, operationSuggested) => {
  // The day we add links to docs, we will use something like this
  // return (
  //   <Trans
  //     i18nKey='test_tags'
  //     values={{ href: 'value' }}
  //     components={{ link: <a href='https://doclink...' rel='noreferrer' /> }}
  //   />
  // )
  return t(OPERATION_SUGGESTED_TITLE[operationSuggested] ?? 'device_status_problem_unknown_title')
}

const getOperationSuggestedExplanation = (t, operationSuggested) => {
  return t(
    OPERATION_SUGGESTED_EXPLANATION[operationSuggested] ??
      'device_status_problem_unknown_explanation',
    { product: 'BinConnect' }
  )
}

const getOperationSuggestedIcon = (operationSuggested) => {
  return OPERATION_SUGGESTED_ICON[operationSuggested] ?? InfoIcon
}

const propTypes = {
  operationSuggested: PropTypes.string,
}

function OperationSuggestedInfo({ operationSuggested }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!operationSuggested || !isKnownOperationSuggested(operationSuggested)) return null

  const Icon = getOperationSuggestedIcon(operationSuggested)
  const short =
    OPERATION_SUGGESTED_SHORT[operationSuggested] &&
    t(OPERATION_SUGGESTED_SHORT[operationSuggested])
  const explanation = getOperationSuggestedExplanation(t, operationSuggested)
  const title = getOperationSuggestedTitle(t, operationSuggested)

  // The case may not be handled yet
  if (!short || !Icon) return null

  return (
    <QuickInfo
      short={short}
      title={title}
      explanation={explanation}
      color={theme.palette.error.main}
      Icon={Icon}
    />
  )
}

OperationSuggestedInfo.propTypes = propTypes

export { getOperationSuggestedExplanation, getOperationSuggestedTitle, getOperationSuggestedIcon }
export default OperationSuggestedInfo
