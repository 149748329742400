import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Tooltip } from '@mui/material'
import {
  ArrowDownIconBig,
  ArrowUpIconBig,
  ContentIconBold,
  PoiIconBold,
  SiloIconBold,
  StatusIconBold,
  UsersIconBold,
} from '../icons'
import { ArrowLeftIcon, CalendarIcon } from '@mui/x-date-pickers'

const sectionIconChip = {
  group: <PoiIconBold />,
  device_content: <ContentIconBold />,
  level_filter: <SiloIconBold />,
  status: <StatusIconBold />,
  bin: <SiloIconBold />,
  day: <CalendarIcon />,
  user: <UsersIconBold />,
  back: <ArrowLeftIcon />,
  down: <ArrowDownIconBig />,
  up: <ArrowUpIconBig />,
}

const propTypes = {
  section: PropTypes.oneOf([
    'group',
    'bin',
    'device_content',
    'level_filter',
    'status',
    'day',
    'user',
    'back',
    'down',
    'up',
  ]).isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
}

const defaultProps = {
  label: null,
  tooltip: null,
}

function NanoChip({ label, section, tooltip, ...props }) {
  const defaultIcon = sectionIconChip[section] ?? <UsersIconBold />
  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Chip sx={{ backgroundColor: '#ffffff' }} icon={defaultIcon} label={label} {...props} />
        </Tooltip>
      ) : (
        <Chip sx={{ backgroundColor: '#ffffff' }} icon={defaultIcon} label={label} {...props} />
      )}
    </>
  )
}

NanoChip.propTypes = propTypes
NanoChip.defaultProps = defaultProps

export default NanoChip
