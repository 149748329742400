import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { client } from '../../shared/apiClient'
import Forecasts from './components/Forecasts'
import NanoSection from '../../shared/components/NanoSection'
import Historic from './components/Historic'
import DeviceCharacteristics from './components/DeviceCharacteristics'
import TopPage from '../layout/TopPage'
import {
  useCanNavigateStore,
  useDashboardStore,
  useWorkspaceSettingsStore,
} from '../../shared/store'
import DeviceModal, { useDeviceModal } from '../dashboard/DeviceModal'
import { ToastContext } from '../../shared/contexts'
import Explanations from './components/Explanations'
import DeviceFirstSection from './components/DeviceFirstSection'
import DeviceList from './components/DeviceList'
import { LinkIcon } from '../../shared/icons/index'
import useNewTabNavigate from '../../shared/customHooks/useNewTabNavigate'
import { TrackVisitedDevice } from '../../shared/globalSearchStore'

const propTypes = {}

const defaultProps = {}

function Device() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  /** test pour la navigation entre les devicfs */
  const newTabNavigate = useNewTabNavigate()
  const pagination = useDashboardStore((state) => state.pagination)
  const data = useDashboardStore((state) => state.data)
  const canNavigate = useCanNavigateStore((state) => state.canNavigate)
  const toastContext = useContext(ToastContext)
  const { id } = useParams()
  /** @type {[import('../../../openapiDoc').components['schemas']['dashboardV2'][0], React.Dispatch<import('../../../openapiDoc').components['schemas']['dashboardV2'][0]>]} */
  const [device, setDevice] = useState({})
  const disableOrders =
    useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'
  const [dataLoading, setDataLoading] = useState(true)
  const { deviceModalIsOpen, openDeviceModal } = useDeviceModal()
  const currentIndex = data.findIndex((device) => device.device_reference === id)

  const devicesCombined = device.devices_combined?.map((device) => ({
    ...device,
    has_parent_combined_device: true,
  }))

  useEffect(() => {
    if (currentIndex === data.length - 1) {
      if (pagination.page < pagination.pageCount) {
        const nextPage = pagination.page + 1
        useDashboardStore.getState().fetchData(nextPage)
      }
    }
  }, [currentIndex, data, pagination])

  const getData = useCallback(async () => {
    setDataLoading(true)
    try {
      const response = await client.GET('/v2/dashboard/{device_id}', {
        params: { path: { device_id: id } },
      })
      if (response.data.length === 0) {
        navigate('/not_found')
      } else {
        setDevice(response.data[0])
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setDataLoading(false)
    }
  }, [id, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [getData, id])

  const handleDeviceUpdated = () => {
    toastContext.sendMessage(t('device_snackbar_alert_updated'))
    getData()
  }

  const handlePreviousDevice = async (event) => {
    if (currentIndex > 0) {
      const previousDevice = data[currentIndex - 1]
      newTabNavigate(`/devices/${previousDevice.device_reference}`, { event })
    }
  }
  const handleNextDevice = async (event) => {
    const nextDevice = data[currentIndex + 1]
    if (nextDevice) {
      newTabNavigate(`/devices/${nextDevice.device_reference}`, { event })
    }
  }

  return (
    <>
      {device && <TrackVisitedDevice id={id} />}
      <TopPage
        back
        backToParent={canNavigate}
        canNavigate={canNavigate}
        handlePrevious={canNavigate && currentIndex > 0 && handlePreviousDevice}
        handleNext={canNavigate && currentIndex < pagination.rowCount - 1 && handleNextDevice}
        renderTitle={
          !dataLoading ? (
            <Stack
              display="flex"
              justifyContent="center"
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Typography variant="h1" color="black">
                {device.device_name}
              </Typography>
              {device.is_combined && <LinkIcon height={24} width={24} />}
            </Stack>
          ) : (
            <Stack
              display="flex"
              justifyContent="center"
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Skeleton variant="text" width={200} height={40} />
            </Stack>
          )
        }
      />
      <Grid container direction="column" sx={{ pb: 30 }}>
        <NanoSection pt={0}>
          <DeviceFirstSection
            device={device}
            loading={dataLoading}
            handleEdit={() => openDeviceModal(true)}
            displayOrder={!disableOrders}
          />
        </NanoSection>
        <NanoSection>
          <Explanations data={device} loading={dataLoading} />
        </NanoSection>
        {device.is_combined && (
          <NanoSection title={t('combined_bins_title')}>
            <DeviceList
              data={devicesCombined}
              loading={dataLoading}
              nanoPaperProps={{ sx: { pt: 0, pb: 0 } }}
            />
          </NanoSection>
        )}
        {device.status === 'ok' && !dataLoading ? (
          <NanoSection
            title={device.has_parent_combined_device ? t('level') : t('device_forecasts_title')}
            loading={dataLoading}
          >
            <Forecasts data={[device]} loading={dataLoading} />
          </NanoSection>
        ) : (
          <NanoSection
            loading={dataLoading}
            title={device?.has_parent_combined_device ? t('level') : t('device_forecasts_title')}
          >
            <Forecasts data={[device]} loading={dataLoading} />
          </NanoSection>
        )}
        <NanoSection title={t('device_info')} loading={dataLoading}>
          <DeviceCharacteristics data={device} loading={dataLoading} />
        </NanoSection>
        <NanoSection title={t('device_historic_title')} loading={dataLoading}>
          <Historic
            loading={dataLoading}
            deviceRef={device.device_reference}
            deviceName={device.device_name}
            deviceCorrectionType={device.configuration?.correction_type}
            startDisplayDate={device.start_display_date}
            installDate={device.device_install_date}
            capaMax={device.capa_max}
            devicesCombined={devicesCombined}
          />
        </NanoSection>
      </Grid>

      {!dataLoading && device && deviceModalIsOpen && (
        <DeviceModal
          isOpen={deviceModalIsOpen}
          onClose={() => openDeviceModal(false)}
          onSuccess={handleDeviceUpdated}
          device={device}
        />
      )}
    </>
  )
}

Device.defaultProps = defaultProps
Device.propTypes = propTypes

export default Device
