import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Skeleton } from '@mui/material'

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  pt: PropTypes.number,
  loading: PropTypes.bool,
}

const defaultProps = { title: null, pt: 3, loading: false }

function NanoSection({ title, pt, children, loading }) {
  return (
    <Grid item container direction="column" spacing={2} paddingTop={pt}>
      {!!title && (
        <Grid item>
          {loading ? (
            <Skeleton variant="text" width={125} height={40} />
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
        </Grid>
      )}
      <Grid item>{children}</Grid>
    </Grid>
  )
}

NanoSection.propTypes = propTypes
NanoSection.defaultProps = defaultProps

export default NanoSection
