import React from 'react'
import PropTypes from 'prop-types'
import { client } from '../../apiClient'
import SelectDevicesOrGroups from './SelectDevicesOrGroups'

const fetchOptions = async () => {
  const response = await client.GET('/v1/groups', {
    params: {
      query: {
        is_poi: true,
        // @ts-ignore @TODO: issue with api doc
        withRelated: [
          'devices.deviceCombinedDevices',
          'devices.deviceCombinedDevice',
          'devices.groups',
          'devices.deviceContent',
        ].join(','),
      },
    },
  })

  return response.data.map((group) => {
    const devices = group.devices.filter(
      (device) =>
        device.deviceCombinedDevice?.length === 0 && device.deviceCombinedDevices?.length === 0
    )

    return {
      label: group.group_name,
      id: group.group_id,
      section: 'group',
      ...group,
      devices,
    }
  })
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string,
  }),
}

const defaultProps = {
  value: null,
}

function SelectUncombinedDevicesOnly({ onChange, value, label, error, required }) {
  return (
    <SelectDevicesOrGroups
      label={label}
      onChange={onChange}
      error={error}
      value={value}
      required={required}
      fetchOptions={fetchOptions}
      withGroups={false}
    />
  )
}

SelectUncombinedDevicesOnly.propTypes = propTypes
SelectUncombinedDevicesOnly.defaultProps = defaultProps

export default SelectUncombinedDevicesOnly
