import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Skeleton, Stack, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { client } from '../../../shared/apiClient'
import NanoSelectDateRange from '../../../shared/components/NanoSelectDateRange'
import FilterBar from '../../../shared/components/FilterBar'
import NanoSelectMultiple from '../../../shared/components/NanoSelectMultiple'
import CalibrationsTable from '../../../shared/components/CalibrationsTable'
import { dateShortWithTimeandYear, getGMT } from '../../../shared/utils/dateUtils'
import OrdersList from '../../orders/list/OrdersList'
import { downloadCSV } from '../../../shared/utils/exportCsv'
import { ToastContext } from '../../../shared/contexts'
import { columnLevel } from '../../dashboard/models/exportModel'
import dayjs from 'dayjs'
import DeviceLevelChart from './DeviceLevelChart'
import SkeletonButton from '../../../shared/components/SkeletonButton'

const TABS = {
  LEVEL: 0,
  CALIBRATION: 1,
  ORDERS: 2,
}

const TabsLabel = (t) => ({
  [TABS.LEVEL]: t('device_history_bin_level'),
  [TABS.CALIBRATION]: t('admin_calibration_title'),
  [TABS.ORDERS]: t('orders_title'),
})

/**
 * @typedef {Object} DateRange
 * @property {Dayjs} from - The start date of the range.
 * @property {Dayjs} to - The end date of the range.
 */

/**
 * @param {number} tab
 * @param {Dayjs | null} installDate
 * @param {Dayjs | null} startDisplayDate
 * @returns {Dayjs}
 */
function getMinDateForTab(tab, installDate, startDisplayDate) {
  const absoluteMinimumDate = dayjs().subtract(2, 'years').startOf('day')

  switch (tab) {
    case TABS.LEVEL:
      return startDisplayDate
        ? dayjs.max(absoluteMinimumDate, startDisplayDate || undefined)
        : absoluteMinimumDate
    case TABS.CALIBRATION:
      return installDate
        ? dayjs.max(absoluteMinimumDate, installDate || undefined)
        : absoluteMinimumDate
    case TABS.ORDERS:
    default:
      return absoluteMinimumDate
  }
}

/**
 *
 * @param {DateRange} dateRange
 * @param {number} tab
 * @param {Dayjs | null} installDate
 * @param {Dayjs | null} startDisplayDate
 * @returns {DateRange}
 */
function constrainDateRangeForTab(tab, dateRange, installDate, startDisplayDate) {
  const minDate = getMinDateForTab(tab, installDate, startDisplayDate)
  const maxDate = tab === TABS.ORDERS ? undefined : dayjs().endOf('day')
  return {
    from: dayjs.max(dateRange.from, minDate ?? dateRange.from),
    to: dayjs.min(dateRange.to, maxDate ?? dateRange.to),
  }
}

/**
 *
 * @param {Partial<DateRange>} preferredDateRange
 * @param {DateRange} defaultDateRange
 * @return {DateRange}
 */
function applyDefaultDateRange(preferredDateRange, defaultDateRange) {
  return {
    from: preferredDateRange.from || defaultDateRange.from,
    to: preferredDateRange.to || defaultDateRange.to,
  }
}

const propTypes = {
  deviceRef: PropTypes.string,
  deviceName: PropTypes.string,
  deviceCorrectionType: PropTypes.oneOf(['continuous', 'stepwise', 'notcorrected', null]),
  loading: PropTypes.bool,
  devicesCombined: PropTypes.array,
  capaMax: PropTypes.number,
  startDisplayDate: PropTypes.string,
  installDate: PropTypes.string,
}

const defaultProps = {
  loading: true,
  devicesCombined: [],
  capaMax: null,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
function Historic({
  deviceRef,
  deviceName,
  deviceCorrectionType,
  loading,
  devicesCombined,
  capaMax,
  startDisplayDate,
  installDate,
}) {
  const [graphData, setGraphData] = useState([])
  const [exportData, setExportData] = useState([])
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const [typeFilter, setTypeFilter] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(TABS.LEVEL)
  const [preferredDateRange, setPreferredDateRange] = useState({
    from: undefined,
    to: undefined,
  })

  // Memoize value to avoid re-rendering because of Date.now() changing
  const defaultDateRange = useMemo(
    () =>
      constrainDateRangeForTab(
        selectedTab,
        {
          from:
            selectedTab === TABS.CALIBRATION
              ? dayjs().subtract(1, 'year').startOf('day')
              : dayjs().subtract(1, 'month').startOf('day'),
          to:
            selectedTab === TABS.ORDERS
              ? // orders are filtered by delivery date, which can be in the future
                dayjs().add(1, 'year').endOf('day')
              : dayjs().endOf('day'),
        },
        installDate ? dayjs(installDate) : undefined,
        startDisplayDate ? dayjs(startDisplayDate) : undefined
      ),
    [selectedTab, installDate, startDisplayDate]
  )

  const displayedDateRange = useMemo(
    () =>
      constrainDateRangeForTab(
        selectedTab,
        applyDefaultDateRange(preferredDateRange, defaultDateRange),
        installDate ? dayjs(installDate) : undefined,
        startDisplayDate ? dayjs(startDisplayDate) : undefined
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      preferredDateRange.from,
      preferredDateRange.to,
      defaultDateRange,
      selectedTab,
      installDate,
      startDisplayDate,
    ]
  )

  const displayedFromTimestamp = displayedDateRange.from.toISOString()
  const displayedToTimestamp = displayedDateRange.to.toISOString()

  const calibrationFilters = [
    // Which device
    devicesCombined.length
      ? `idDevice=${devicesCombined?.map((dc) => dc.device_reference).join(',')}`
      : `idDevice=${deviceRef}`,
    // Which date range
    `start_date=${displayedDateRange.from.toISOString()}`,
    `end_date=${displayedDateRange.to.toISOString()}`,
    selectedTab === TABS.CALIBRATION && typeFilter.length > 0
      ? `type=${typeFilter.join(',')}`
      : null,
  ]
    .filter(Boolean)
    .join('&')

  useEffect(() => {
    setLoading(true)
    const controller = new AbortController()
    let isInvalidated = false

    if (!deviceRef || ![TABS.LEVEL].includes(selectedTab)) {
      return
    }

    client
      .POST('/v1/get-graph-data', {
        body: {
          // @ts-ignore @TODO: change doc error on api side
          device_ids: [deviceRef],
          data_types: ['level'],
          is_last_value: false,
          from_timestamp: displayedFromTimestamp,
          to_timestamp: displayedToTimestamp,
        },
        signal: controller.signal,
      })
      .then((d) => {
        if (isInvalidated) return

        // @ts-ignore @TODO: fix api doc
        const convertedData = d.data.data[0].data_points.map((datum) => ({
          ...datum,
          timestamp: new Date(datum.timestamp).getTime(),
          dateTime: datum.timestamp,
        }))
        setGraphData(convertedData)
        setLoading(false)
        if (selectedTab === TABS.LEVEL) {
          const exportedData = d.data.data[0].data_points.map((datum) => ({
            dateTime: dateShortWithTimeandYear(new Date(datum.timestamp)),
            device_reference: deviceRef,
            device_name: deviceName,
            level_percent: datum.level_percent,
            level_t: datum.level_t,
            missingWeight: datum.missingWeight,
          }))
          setExportData(exportedData)
        } else {
          const exportedData = d.data.data[0].data_points.map((datum) => ({
            dateTime: dateShortWithTimeandYear(new Date(datum.timestamp)),
            device_reference: deviceRef,
            device_name: deviceName,
            value: datum.value,
          }))
          setExportData(exportedData)
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.error(e)
          // TODO: better error message
          toastContext.sendMessage(t('api_common_error'), 'error')
        }
      })

    // Invalidate this fetch on changing parameters
    return () => {
      controller.abort()
      isInvalidated = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedFromTimestamp, displayedToTimestamp, deviceRef, selectedTab])

  return (
    <>
      <FilterBar
        withTabs
        loading={loading}
        isExport={[TABS.LEVEL].includes(selectedTab)}
        exportAction={() =>
          graphData.length
            ? downloadCSV(
                exportData,
                columnLevel(t, getGMT()),
                `${deviceRef}_${TabsLabel(t)[selectedTab]}_${new Date().toLocaleDateString()}.csv`
              )
            : toastContext.sendMessage(t('no_data_to_export'), 'error')
        }
      >
        {loading ? (
          <Stack direction="row" justifyContent={'flex-start'} spacing={2}>
            {[TABS.LEVEL, TABS.CALIBRATION, TABS.ORDERS].map((tab) => (
              <Skeleton height={30} width={100} />
            ))}
          </Stack>
        ) : (
          <Tabs
            variant="scrollable"
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            {[TABS.LEVEL, TABS.CALIBRATION, TABS.ORDERS].map((tab) => (
              <Tab key={tab} label={TabsLabel(t)[tab]} />
            ))}
          </Tabs>
        )}
        {loading ? (
          <>
            <SkeletonButton />
            <SkeletonButton />
          </>
        ) : (
          <>
            <NanoSelectDateRange
              placeholder="date"
              value={[displayedDateRange.from, displayedDateRange.to]}
              handleSelect={([from, to]) => {
                setPreferredDateRange({
                  from: from?.startOf('day') || undefined,
                  to: to?.endOf('day') || undefined,
                })
              }}
              dateRangePickerProps={{
                minDate: getMinDateForTab(
                  selectedTab,
                  installDate ? dayjs(installDate) : undefined,
                  startDisplayDate ? dayjs(startDisplayDate) : undefined
                ),
                disableFuture: selectedTab !== TABS.ORDERS,
              }}
            />
            {selectedTab === TABS.CALIBRATION && (
              <NanoSelectMultiple
                placeholder="type"
                options={[
                  { name: t('level'), value: 'level' },
                  { name: t('delivery'), value: 'delivery' },
                ]}
                value={typeFilter}
                handleSelect={(value) => {
                  setTypeFilter(value)
                }}
              />
            )}
          </>
        )}
      </FilterBar>

      {selectedTab === TABS.LEVEL && (
        <DeviceLevelChart
          graphData={graphData}
          isLoading={isLoading || loading}
          continuous={deviceCorrectionType === 'continuous'}
          capaMax={capaMax}
        />
      )}

      {selectedTab === TABS.CALIBRATION && (
        <CalibrationsTable withDevice filters={calibrationFilters} />
      )}
      {selectedTab === TABS.ORDERS && (
        <OrdersList
          disableOrderEdition
          dateFilter={[displayedDateRange.from, displayedDateRange.to]}
          siloId={deviceRef}
        />
      )}
    </>
  )
}

Historic.propTypes = propTypes
Historic.defaultProps = defaultProps

export default Historic
