import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import CalibLevelModal from './CalibLevelModal'
import CalibDeliveryModal, { useCalibDeliveryModal } from './CalibDeliveryModal'
import ConfirmationModal from './ConfirmationModal'
import { CallIcon, OrdersIcon, SiloIcon, TruckIcon } from '../../../shared/icons'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import OrderForm from '../../orders/form/OrderForm'
import { client } from '../../../shared/apiClient'
import { ToastContext } from '../../../shared/contexts'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import OperationSuggestedInfo from '../../../shared/components/OperationSuggestedInfo'
import QuickButton from './QuickButton'
import {
  isKnownOperationSuggested,
  isKnownWarningCause,
} from '../../../shared/models/operationSuggested'
import WarningCauseInfo from '../../../shared/components/WarningCauseInfo'
import { useSessionStore } from '../../../shared/store'
import { supportedSupportLanguages } from '../../../shared/utils/langUtils'
import SkeletonButton from '../../../shared/components/SkeletonButton'

const propTypes = {
  /** @type {PropTypes.Requireable<import('../../../../openapiDoc').components['schemas']['dashboardV2'][0]>} */
  device: PropTypes.shape({}),
  /** indicate that if we need to hide some action, also imply that the buttons will in outlined variant */
  fromDashboard: PropTypes.bool,
  displayOrder: PropTypes.bool,
  adminMode: PropTypes.func,
  loading: PropTypes.bool,
}

const defaultProps = { displayOrder: true, fromDashboard: false, adminMode: null, loading: false }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function QuickActions({ displayOrder, device, loading, fromDashboard, adminMode }) {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const { calibDeliveryFormIsOpen, openCalibDeliveryModal } = useCalibDeliveryModal({
    useUrl: !fromDashboard,
  })
  const [calibLevelFormIsOpen, setCalibLevelFormIsOpen] = useState(false)
  const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false)
  const [orderFormIsOpen, setOrderFormIsOpen] = useState(false)
  const userLanguage = useSessionStore(
    (state) => state.user.configuration.preferred_language?.split('-')[0]
  )
  const supportedLanguage =
    supportedSupportLanguages.find((lang) => lang.split('-')[0] === userLanguage) ?? 'en'
  const unCombineDevice = async (device) => {
    await client
      .DELETE('/v1/devices/{id}', { params: { path: { id: device.device_id } } })
      .then(() => {
        sendEvent('combined_unlinked')
        toastContext.sendMessage(t('admin_combined_snackbar_alert_deleted'))

        adminMode()
      })
  }

  const requiresLevel =
    device.status !== 'problem' && device.metadata?.info_to_calibrate?.includes('level')
  const requiresDelivery =
    device.status !== 'problem' && device.metadata?.info_to_calibrate?.includes('delivery')

  return (
    <>
      <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="flex-end" useFlexGap>
        {loading ? (
          <SkeletonButton width={150} height={40} />
        ) : (
          <>
            {fromDashboard &&
              (device.status !== 'problem' &&
              isKnownWarningCause(device.metadata?.warning_cause) ? (
                <WarningCauseInfo warningCause={device.metadata?.warning_cause} />
              ) : (
                device.status === 'problem' &&
                (isKnownOperationSuggested(device.metadata?.operation_suggested) ? (
                  <OperationSuggestedInfo
                    operationSuggested={device.metadata?.operation_suggested}
                  />
                ) : (
                  <QuickButton
                    fromDashboard={fromDashboard}
                    color="problem"
                    Icon={CallIcon}
                    title={t('button_support')}
                    onClick={() =>
                      window.open(`https://binconnect.crisp.help/${supportedLanguage}`)
                    }
                  />
                ))
              ))}

            {displayOrder &&
              ((device.status === 'ok' && !device.metadata?.info_to_calibrate) ||
                !fromDashboard) && (
                <QuickButton
                  fromDashboard={fromDashboard}
                  color="secondary"
                  Icon={OrdersIcon}
                  title={t('device_quick_action_order_title')}
                  onClick={() => setOrderFormIsOpen(true)}
                />
              )}

            {(requiresDelivery || !fromDashboard) && !device.is_combined && (
              <QuickButton
                fromDashboard={fromDashboard}
                color="pending"
                Icon={TruckIcon}
                title={
                  requiresDelivery
                    ? t('device_quick_action_delivery_required')
                    : t('device_quick_action_calibrate_delivery_title')
                }
                onClick={() => openCalibDeliveryModal(true)}
              />
            )}

            {(requiresLevel || !fromDashboard) && !device.is_combined && (
              <QuickButton
                fromDashboard={fromDashboard}
                color="pending"
                Icon={SiloIcon}
                title={
                  requiresLevel
                    ? t('device_quick_action_level_required')
                    : t('device_quick_action_calibrate_lvl_title')
                }
                onClick={() => setCalibLevelFormIsOpen(true)}
              />
            )}

            {!!adminMode && (
              <QuickButton
                fromDashboard={fromDashboard}
                color="secondary"
                Icon={LinkOffIcon}
                title={t('unlink_combined')}
                onClick={() => {
                  unCombineDevice(device)
                }}
              />
            )}
          </>
        )}
      </Stack>

      {calibLevelFormIsOpen && (
        <CalibLevelModal
          device={device}
          isOpen={calibLevelFormIsOpen}
          onClose={() => setCalibLevelFormIsOpen(false)}
          onSuccess={() => setConfirmationPopupIsOpen(true)}
        />
      )}

      {calibDeliveryFormIsOpen && (
        <CalibDeliveryModal
          device={device}
          isOpen={calibDeliveryFormIsOpen}
          onClose={() => openCalibDeliveryModal(false)}
          onSuccess={() => setConfirmationPopupIsOpen(true)}
        />
      )}

      {confirmationPopupIsOpen && (
        <ConfirmationModal
          isOpen={confirmationPopupIsOpen}
          onClose={() => setConfirmationPopupIsOpen(false)}
        />
      )}

      {orderFormIsOpen && (
        <OrderForm
          isOpen={orderFormIsOpen}
          deviceReference={
            device.is_combined
              ? device.devices_combined?.map((dc) => dc.device_reference)
              : [device.device_reference]
          }
          onClickCloseButton={() => setOrderFormIsOpen(false)}
        />
      )}
    </>
  )
}

QuickActions.propTypes = propTypes
QuickActions.defaultProps = defaultProps

export default QuickActions
