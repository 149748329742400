import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import NotFoundData from '../../../shared/components/NotFoundData'
import DeviceList from './DeviceList'
import { useDashboardStore } from '../../../shared/store'

const propTypes = {}

const defaultProps = {}

function DeviceListView() {
  const filterModel = useDashboardStore((state) => state.filterModel)
  const [dataLoading, setDataLoading] = useState(true)
  const pagination = useDashboardStore((state) => state.pagination)
  const data = useDashboardStore((state) => state.data)

  const fetchPage = () => useDashboardStore.getState().fetchData(pagination.page + 1)

  useEffect(() => {
    setDataLoading(true)
    useDashboardStore.getState().emptyDashboard()
    useDashboardStore
      .getState()
      .fetchData(1)
      .finally(() => {
        setDataLoading(false)
      })
  }, [filterModel])

  return (
    <>
      {!dataLoading && !data.length ? (
        <NotFoundData />
      ) : (
        <DeviceList
          canNavigate
          data={data}
          loading={dataLoading}
          remoteRowCount={pagination.rowCount}
          fetchPage={fetchPage}
        />
      )}
    </>
  )
}

DeviceListView.propTypes = propTypes
DeviceListView.defaultProps = defaultProps

export default DeviceListView
