import React from 'react'
import { Grid, Typography, IconButton, useTheme, Skeleton, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../icons/PoiIconBold.svg'
import { ReactComponent as HashtagIcon } from '../icons/HashtagIcon.svg'
import { ReactComponent as Arrow } from '../icons/ArrowRightIconBold.svg'
import NanoMenuItem from './NanoMenuItem'
import { ContentIcon } from '../icons/index'
import Highlighter from 'react-highlight-words'

function SearchDeviceResult({ loading, device, highlightText, onClick }) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <NanoMenuItem onClick={onClick}>
      <Grid
        pl={2}
        container
        spacing={0}
        direction="column"
        style={{
          minWidth: 0, // prevents overflow (default to auto because of flex)
        }}
      >
        <Grid item container alignItems="center">
          <Grid item>
            {loading ? (
              <Skeleton variant="text" width={300} height={18} />
            ) : (
              <Typography variant="h5" textOverflow={'ellipsis'} noWrap overflow={'hidden'}>
                <NanoHighlighter
                  autoEscape={true}
                  textToHighlight={device?.device_name}
                  searchWords={[highlightText]}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <PoiIcon color={theme.palette.text.secondary} />
          </Grid>
          <Grid item xs={8}>
            {loading ? (
              <Skeleton width={160} height={20} />
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                textOverflow={'ellipsis'}
                noWrap
                overflow={'hidden'}
              >
                <NanoHighlighter
                  autoEscape={true}
                  textToHighlight={device?.group_poi?.group_poi_name}
                  searchWords={[highlightText]}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <ContentIcon color={theme.palette.error.main} />
          </Grid>
          <Grid item xs={8}>
            {loading ? (
              <Skeleton width={200} height={20} />
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                textOverflow={'ellipsis'}
                noWrap
                overflow={'hidden'}
              >
                <NanoHighlighter
                  autoEscape={true}
                  textToHighlight={device?.deviceContent ?? t('not_applicable')}
                  searchWords={[highlightText]}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <HashtagIcon color={theme.palette.error.main} />
          </Grid>
          <Grid item xs={8}>
            {loading ? (
              <Skeleton width={200} height={20} />
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                textOverflow={'ellipsis'}
                noWrap
                overflow={'hidden'}
              >
                <NanoHighlighter
                  autoEscape={true}
                  textToHighlight={device?.device_reference}
                  searchWords={[highlightText]}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <IconButton>
        <Arrow fill={theme.palette.secondary.main} stroke={theme.palette.secondary.main} />
      </IconButton>
    </NanoMenuItem>
  )
}

const NanoHighlighter = styled((props) => <Highlighter autoEscape={true} {...props} />)(
  ({ theme }) => ({
    mark: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  })
)

export default SearchDeviceResult
