import { createContext } from 'react'

const GroupCellContext = createContext({
  groups: [],
  // token: null,
  rmvGroup: () => {},
})

const DialogContext = createContext({
  deletedOrEdited: () => {},
})

const ToastContext = createContext({
  /**
   *
   * @param {string} message
   * @param {string} severity
   * 'success' (default) | 'info' | 'warning' | 'error')
   */
  sendMessage: (message, severity = 'success') => {},
  handleComingSoon: () => {},
})

export { GroupCellContext, DialogContext, ToastContext }
