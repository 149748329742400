import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { client } from '../../../shared/apiClient'
import NanoDialog from '../../../shared/components/NanoDialog'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import SelectUncombinedDevicesOnly from '../../../shared/components/selectDevicesOrGroups/SelectUncombinedDevicesOnly'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
  row: {},
}

function AdminCombinedModal({ isOpen, onClose, onSuccess }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)

  const { handleSubmit, control } = useForm()

  const handleClose = () => {
    // reset();
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)
    client
      .POST('/v1/devices/combine', {
        body: {
          device_ids:
            data.device_ids[0]?.section === 'group'
              ? data.device_ids[0].devices.map((d) => d.device_id)
              : data.device_ids?.map((d) => d.device_id),
          device_name: data.device_name,
        },
      })
      .then(() => {
        sendEvent('combined_created')
        setIsLoading(false)
        handleClose()
        onSuccess()
      })
      .catch((err) => {
        setDisplayAlert(err.message)
        setIsLoading(false)
      })
  }

  return !isOpen ? null : (
    <NanoDialog open={isOpen} onClose={handleClose} title={t('create_combined')}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="device_name"
            defaultValue={null}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('combined_name')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 63 }}
              />
            )}
          />
          <Controller
            control={control}
            name="device_ids"
            defaultValue={[]}
            rules={{
              validate: {
                sameFarm: (value) =>
                  value.every((item) => item.farm_name === value[0].farm_name) ||
                  t('same_combined_farm'),
                minLength: (value) =>
                  (value[0]?.section === 'group' && value[0].devices.length >= 2) ||
                  value.length >= 2 ||
                  t('combined_length'),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <SelectUncombinedDevicesOnly
                label={t('create_combined')}
                error={error}
                onChange={(_, data) => field.onChange(data)}
                value={field.value}
                required
              />
            )}
          />
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type="submit"
            loadingPosition="start"
            // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
            fullWidth
          >
            {t('validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

AdminCombinedModal.propTypes = propTypes
AdminCombinedModal.defaultProps = defaultProps

export default AdminCombinedModal
