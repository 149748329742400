import { useEffect, useRef } from 'react'
import keys from 'ctrl-keys'

const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)

/**
 * Bind a hotkey to a callback
 */
function useHotkey({
  key, // can be "mod" for cmd on mac, ctrl on windows
  callback,
  enabled = true,
  preventDefault = false,
  stopPropagation = false,
  action = 'keydown', // keyup
}) {
  if (key.includes('mod')) {
    key = key.replace('mod', isMac ? 'meta' : 'ctrl')
  }

  const callbackRef = useRef(() => {})
  callbackRef.current = callback

  useEffect(() => {
    if (!enabled) return
    const handler = keys()

    handler.add(key, (event) => {
      if (preventDefault) event.preventDefault()
      if (stopPropagation) event.stopPropagation()
      callbackRef.current()
    })

    window.addEventListener(action, handler.handle)
    return () => {
      window.removeEventListener(action, handler.handle)
    }
  }, [key, action, enabled, preventDefault, stopPropagation])
}

export default useHotkey
