import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  // To fetch the options for the autocomplete yourself
  fetchOptions: PropTypes.func,
}

const defaultProps = {
  value: [],
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDevices({ onChange, value, ...props }) {
  const { t } = useTranslation()

  return (
    <NanoAutocomplete
      label={t('bin', { count: 100 })}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_device_to_add')}
        </Typography>
      }
      fetchOptions={fetchOptions}
      onChange={onChange}
      value={value}
      openOnFocus
      selectOnFocus
      textFieldProps={{ autoFocus: true }}
      {...props}
    />
  )
}

SelectDevices.propTypes = propTypes
SelectDevices.defaultProps = defaultProps

async function fetchOptions() {
  const responses = await client.GET('/v1/devices', { params: { query: { is_combined: false } } })
  return responses.data.map((device) => ({
    label: `${device.device_name} - ${device.farm_name}`,
    id: device.device_reference,
    section: 'bin',
    ...device,
    device_content: null,
  }))
}

/**
 * To memoize the options across several SelectDevices instances
 * use this hook and pass the fetchOptions function to the SelectDevices components
 */
function useSelectDevicesOptions() {
  const optionsPromise = useRef(null)

  const fetchDeviceOptions = useCallback(async () => {
    if (optionsPromise.current === null) {
      optionsPromise.current = fetchOptions()
    }

    return await optionsPromise.current
  }, [optionsPromise])

  // Pre-fetch on mount
  useEffect(() => {
    optionsPromise.current = fetchOptions()
  }, [])

  return {
    fetchDeviceOptions,
  }
}

export { useSelectDevicesOptions }
export default SelectDevices
